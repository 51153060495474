// ATTENTION
// this reducer should only be used by the API Middleware,
// there should not be any action creator that directly
// references any of the reductions defined here

import merge from "lodash/merge";
import {
  API_DATA_REQUEST,
  API_DATA_SUCCESS,
  API_DATA_DELETE,
  API_DATA_FLUSH_ALL,
  deleteFromState
} from "src/store/api_middleware";
import { extractController } from "src/libs/APIFactory";

const initialState = {
  meta: {}
};

const data = (state = initialState, action) => {
  const controller = !action.controller
    ? action.endpoint
      ? extractController(action.endpoint)
      : ""
    : action.controller;
  switch (action.type) {
    case API_DATA_SUCCESS:
      console.debug("[DEBUG] - Data Reducer - " + API_DATA_SUCCESS, action);
      return merge(
        {},
        state,
        merge({}, action.response ? { [controller]: action.response } : {}, {
          meta: {
            [controller]: {
              [action.endpoint]: { loading: false }
            }
          }
        })
      );
    case API_DATA_REQUEST:
      console.debug("[DEBUG] - Data Reducer - " + API_DATA_REQUEST, action);
      return merge({}, state, {
        meta: {
          [controller]: {
            [action.endpoint]: { loading: true }
          }
        }
      });
    case API_DATA_DELETE:
      console.debug(
        "[DEBUG] - Data Reducer - " + API_DATA_DELETE,
        state,
        action
      );
      return merge({}, deleteFromState(state, controller));
    case API_DATA_FLUSH_ALL:
      console.debug("[DEBUG] - Data Reducer - " + API_DATA_FLUSH_ALL, action);
      return { ...initialState };
    default:
      return merge({}, state);
  }
};
export { data };
