// Redux
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import api_middleware from "src/store/api_middleware";
import * as reducers from "src/store/reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { devToolsEnhancer } from "redux-devtools-extension/logOnlyInProduction";

// setup Redux store with persistance
const persistConfig = {
  key: "root",
  whitelist: ["auth"], // there is a blacklist option available
  // for other options check docs at:
  // https://github.com/rt2zz/redux-persist/blob/master/docs/api.md
  storage
};
const combReducers = combineReducers({ ...reducers });
const persistedReducer = persistReducer(persistConfig, combReducers);
const enhancers = [applyMiddleware(api_middleware), devToolsEnhancer()];
let store = createStore(persistedReducer, {}, compose(...enhancers));
let persistor = persistStore(store);

// if (module.hot) {
//   module.hot.accept("src/store/reducer", () => {
//     // This fetch the new state of the above reducers.
//     const nextRootReducer = require("src/store/reducer").default;
//     store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
//   });
// }

export { store, persistor };
