// ATTENTION
// this reducer should only be used by the API Middleware,
// there should not be any action creator that directly
// references any of the reductions defined here!
//
// An exception was created for API_CACHE_FLUSH_ALL, to
// be used on logout action

import merge from "lodash/merge";
import { Date } from "core-js";
import {
  API_CACHE_GET,
  API_CACHE_SET,
  API_CACHE_DELETE,
  API_CACHE_FLUSH_ALL,
  deleteFromState
} from "src/store/api_middleware";
import { extractController } from "src/libs/APIFactory";

const initialState = {};

const cache = (state = initialState, action) => {
  let cache_ttl = process.env.REACT_APP_API_CACHE_TTL;
  const controller = !action.controller
    ? action.endpoint
      ? extractController(action.endpoint)
      : ""
    : action.controller;
  switch (action.type) {
    case API_CACHE_GET:
      console.debug("[DEBUG] - Cache Reducer - " + API_CACHE_GET, action);
      // first check cache info
      if (state && state[controller] && state[controller][action.endpoint]) {
        let { ttl, timestamp } = state[controller][action.endpoint];
        // ttl is stored in seconds, must mutiply by 1000
        if (Date.now() < ttl * 1000 + timestamp) {
          // cache still valid, return current state
          return merge({}, state);
        }
        // delete endpoint from cache
        return merge({}, deleteFromState(state, controller, action.endpoint));
      }
      return merge({}, state);
    case API_CACHE_SET:
      console.debug("[DEBUG] - Cache Reducer - " + API_CACHE_SET, action);
      if (action.cache && action.cache.ttl) {
        cache_ttl = action.cache.ttl;
      }
      return merge({}, state, {
        [controller]: {
          [action.endpoint]: {
            ttl: cache_ttl,
            timestamp: Date.now()
          }
        }
      });
    case API_CACHE_DELETE:
      console.debug(
        "[DEBUG] - Cache Reducer - " + API_CACHE_DELETE,
        state,
        action
      );
      return merge({}, deleteFromState(state, controller));
    case API_CACHE_FLUSH_ALL:
      console.debug("[DEBUG] - Cache Reducer - " + API_CACHE_FLUSH_ALL, action);
      return {};
    default:
      return merge({}, state);
  }
};
export { cache };
