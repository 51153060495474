import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
// this import came commented with the theme
// import { renderRoutes } from 'react-router-config';

// css
import "./App.scss";
// Containers
const DefaultLayoutContainer = React.lazy(() =>
  import("src/containers/DefaultLayout")
);
// Pages
const LoginContainer = React.lazy(() => import("./views/Pages/Login"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error("[FATAL-ERROR]", info);
  }
  render() {
    return (
      <BrowserRouter>
        <LastLocationProvider>
          <React.Suspense fallback={loading()}>
            {this.state.hasError ? (
              <Route
                to="/500"
                name="Page 500"
                render={props => <Page500 {...props} />}
              />
            ) : (
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={props => <LoginContainer {...props} />}
                />
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={props => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={props => <Page500 {...props} />}
                />
                <Route
                  path="/"
                  name="Home"
                  render={props => <DefaultLayoutContainer {...props} />}
                />
              </Switch>
            )}
          </React.Suspense>
        </LastLocationProvider>
      </BrowserRouter>
    );
  }
}

export default App;
