import merge from "lodash/merge";

const initial_state = {};
const initial_rtable_state = {
  page: undefined,
  pageSize: undefined,
  sorted: [],
  filtered: [],
  resized: [],
  expanded: {}
};

function setRtableState(state, payload = {}) {
  // must always contain a controller
  if (!payload.controller) return state;
  // make a copy of the required state params
  let _state = state ? { ...state } : {};
  _state[payload.controller] = { ...initial_rtable_state, ...payload.data };
  // merge changes to current state
  return merge({}, _state);
}

function removeRtableState(state, payload = {}) {
  let _state = { ...state };
  let controllers = Object.keys(_state);
  if (controllers.length > 0) {
    if (payload.controllers === false) {
      // remove all tables states
      _state = {};
    } else {
      // remove inidicated table states
      let _controllers = Array.isArray(payload.controllers)
        ? payload.controllers
        : [payload.controllers];
      _controllers.forEach(c => {
        _state[c] = initial_rtable_state;
      });
    }
  }
  // deleting from state cannot be merged to state
  // must return the "new" state
  return merge({}, _state);
}

const rtable_state = (state = initial_state, action) => {
  switch (action.type) {
    case "SET_RTABLE_STATE":
      return setRtableState(state, action.payload);
    case "REMOVE_RTABLE_STATE":
      return removeRtableState(state, action.payload);
    default:
  }
  return merge({}, state);
};

export { rtable_state };
