import { Date } from "core-js";

const initial_state = {
  token: "",
  ttl: 0,
  timestamp: Date.now(),
  username: "",
  isAuth: false,
  user_id: "",
  account_id: "",
  role_name: "",
  acl: {}
};

function login(state, payload) {
  if (payload.token && payload.username && payload.ttl) {
    // update state with new login
    const newState = {
      ...state,
      timestamp: Date.now(),
      isAuth: true,
      ...payload
    };
    return { ...state, ...newState };
  }
  return state;
}

function resetAuth(state) {
  // reset auth in draft state
  return initial_state;
}

const auth = (state = initial_state, action) => {
  switch (action.type) {
    case "LOGIN":
      return login(resetAuth(state), action.payload);
    case "LOGOUT":
      return resetAuth(state);
    default:
  }
  return state;
};
export { auth };
