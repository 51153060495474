import merge from "lodash/merge";
import { Date } from "core-js";

const initial_state = {
  msgs: {},
  presented: {},
  auto_close: {}
};

// source: https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
const hashCode = function(str) {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

function setTopMsg(state, payload = {}) {
  // make a copy of the required state params
  let msgs = state.msgs ? { ...state.msgs } : {};
  // generate hash and create new message entry
  if (!payload.msg) payload.msg = "Unknown message!";
  let hash_base = payload.msg + (payload.unique ? "" : Date.now());
  const hash = hashCode(hash_base);
  msgs[hash] = payload;
  // merge changes to current state
  return merge({}, state, { msgs });
}

function addTopMsgToPresented(state, payload = {}) {
  // make a copy of the required state params
  let presented = state.presented ? { ...state.presented } : {};
  let toasts = payload.toasts ? payload.toasts : {};
  let t_keys = toasts ? Object.keys(toasts) : [];
  if (t_keys.length > 0) {
    // add indicated messages to presented
    t_keys.forEach(k => {
      presented[toasts[k].hash] = toasts[k];
    });
  }
  // merge changes to current state
  return merge({}, state, { presented });
}

function addTopMsgToAutoClose(state, payload = {}) {
  // make a copy of the required state params
  let auto_close = state.auto_close ? { ...state.auto_close } : {};
  let toasts = payload.toasts ? payload.toasts : {};
  let t_keys = toasts ? Object.keys(toasts) : [];
  if (t_keys.length > 0) {
    // add indicated messages to auto_close
    t_keys.forEach(k => {
      auto_close[toasts[k].hash] = toasts[k];
    });
  }
  // merge changes to current state
  return merge({}, state, { auto_close });
}

function removeTopMsg(state, payload = {}) {
  let _state = { ...state };
  let _key = payload.key ? payload.key : "msgs";
  let msgs_hashes = Object.keys(_state[_key]);
  if (msgs_hashes.length > 0) {
    if (payload.hashes === false) {
      // remove all msgs from key
      _state[_key] = {};
    } else {
      // remove inidicated messages
      let hashes = Array.isArray(payload.hashes)
        ? payload.hashes
        : [payload.hashes];
      hashes.forEach(h => {
        delete _state[_key][h];
      });
    }
  }
  // deleting from state cannot be merged to state
  // must return the "new" state
  return merge({}, state, { [_key]: _state[_key] });
}

const top_msg = (state = initial_state, action) => {
  switch (action.type) {
    case "SET_TOP_MSG":
      return setTopMsg(state, action.payload);
    case "RESET_TOP_MSG":
      return removeTopMsg(
        addTopMsgToAutoClose(state, {
          toasts: state.presented ? state.presented : {}
        })
      );
    case "REMOVE_TOP_MSG":
      return removeTopMsg(state, action.payload);
    case "ADD_TOP_MSG_TO_PRESENTED":
      return addTopMsgToPresented(state, action.payload);
    case "ADD_TOP_MSG_TO_AUTO_CLOSE":
      return addTopMsgToAutoClose(state, action.payload);
    default:
  }
  return merge({}, state);
};

export { top_msg };
