import axios from "axios";

var baseURL = "";
const hostname = window && window.location && window.location.hostname;
if (/scm-dev/.test(hostname)) {
  baseURL = process.env.REACT_APP_API_URL_DEV;
  // baseURL = process.env.REACT_APP_API_URL;
  // baseURL = "//www.example.com:8080/scm_api/";
  // baseURL = "//www.example.com:8080/scm_apis/";
  // baseURL = "//scn-test.polluxnetwork.com/api-manager/";
} else if (/scm-test/.test(hostname)) {
  baseURL = process.env.REACT_APP_API_URL_TEST;
} else {
  baseURL = process.env.REACT_APP_API_URL;
}

var api = false;
export function api_factory(base_url = baseURL) {
  console.debug("[DEBUG] - API Factory (create)");
  let _api = axios.create({
    baseURL: base_url,
    /////// local dev API
    headers:
      process.env.NODE_ENV === "development" &&
      (base_url.indexOf("example") >= 0 || base_url.indexOf("scn-test") >= 0)
        ? {
            Accept: "application/json,text/plain",
            "Content-Type": "text/plain;charset=UTF-8"
          }
        : {}
    /////// local dev API
  });

  // intercept API response before being passed to data reducer
  _api.interceptors.response.use(
    function(res) {
      console.debug("[DEBUG] - API Call Successful:", res);
      return res.data || false;
    },
    function(error) {
      let error_msg = "Sorry, something went wrong!",
        error_data = null;
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data && error.response.data.messages) {
          error_msg = error.response.data.messages;
        } else {
          error_data = error.response;
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        error_data = error.request;
      } else if (error.message) {
        // Something happened in setting up the request that triggered an Error
        error_data = error.message;
        error_msg = error.message;
      } else if (error.messages) {
        // Something happened in setting up the request that triggered an Error
        error_data = error.messages;
        error_msg = error.messages;
      } else {
        // When all else fails ...
        error_data = error;
      }
      // dump error data on console
      if (error_data) {
        console.error("[ERROR] - API Call error:", error_data);
      }
      let res = {
        status: "ERROR",
        msg: error_msg
      };
      if (error.response && error.response.status) {
        res.code = error.response.status;
      }
      return Promise.reject(res);
    }
  );
  return _api;
}
api = api === false ? api_factory() : api;

export default api;
export { baseURL };

// resolve params into query string, and join with endpoint, and with base url
export function buildEndpoint(endpoint, query_params = "", base_url = baseURL) {
  base_url = base_url.match("^(http)")
    ? base_url
    : base_url.match("^(//)")
    ? "http:" + base_url
    : "http://" + base_url;
  endpoint = endpoint.match("^(/)") ? endpoint : "/" + endpoint;

  /////// local dev API
  if (base_url.indexOf("example") >= 0) {
    console.warn(
      "-------------- adding forward slash to endpoint for local API: ",
      endpoint
    );
    endpoint = endpoint.match("(/)$") ? endpoint : endpoint + "/";
  }
  /////// local dev API

  var url = new URL(endpoint, base_url);
  if (query_params) {
    for (var qp_key in query_params) {
      let qp_values = [];
      switch (qp_key) {
        case "q":
        case "order":
          for (var key in query_params[qp_key]) {
            qp_values.push(key + ":" + query_params[qp_key][key]);
          }
          url.searchParams.set(qp_key, "(" + qp_values.join(",") + ")");
          break;
        case "fields":
          qp_values = Array.isArray(query_params[qp_key])
            ? query_params[qp_key]
            : [query_params[qp_key]];
          url.searchParams.set(qp_key, "(" + qp_values.join(",") + ")");
          break;
        case "limit":
        case "offset":
        case "count":
        default:
          url.searchParams.set(qp_key, query_params[qp_key]);
          break;
      }
    }
  }
  console.debug(
    "[DEBUG] - buildEndpoint - URL (decoded):",
    decodeURIComponent(url.pathname + url.search)
  );
  return decodeURIComponent(url.pathname + url.search);
}

// given an API endpoint (without base URL), retuns the first segment
export function extractController(endpoint) {
  let result = endpoint.match(/^\/(\w+)/);
  return result && result[1] ? result[1] : endpoint;
}
