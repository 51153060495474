import merge from "lodash/merge";

const initial_state = {
  is_visible: true,
  color: "blue",
  bg_color: "white"
};

function setOverlay(state, payload = {}) {
  // make a copy of the required state params
  let _state = { ...state };
  _state = merge(_state, payload);
  // merge changes to current state
  return merge({}, state, _state);
}

const overlay = (state = initial_state, action) => {
  switch (action.type) {
    case "SET_OVERLAY":
      return setOverlay(state, action.payload);
    default:
  }
  return merge({}, state);
};

export { overlay };
