// api
import api, { buildEndpoint, extractController } from "src/libs/APIFactory";

export const API_DATA_REQUEST = "API_DATA_REQUEST";
export const API_DATA_SUCCESS = "API_DATA_SUCCESS";
export const API_DATA_FAILURE = "API_DATA_FAILURE";
export const API_DATA_DELETE = "API_DATA_DELETE";
export const API_DATA_FLUSH_ALL = "API_DATA_FLUSH_ALL";
export const API_CACHE_GET = "API_CACHE_GET";
export const API_CACHE_SET = "API_CACHE_SET";
export const API_CACHE_DELETE = "API_CACHE_DELETE";
export const API_CACHE_FLUSH_ALL = "API_CACHE_FLUSH_ALL";

// method that calls the API lib to actully send the request
function callApi(endpoint, options = {}) {
  const config = {
    url: endpoint,
    ...options
  };
  return api(config).then(function(response) {
    console.debug("[DEBUG] - Middleware API - response", response);
    if (response.status !== "OK") {
      return Promise.reject(response);
    }
    if (response.status === "OK") {
      if (response.data) {
        return Object.assign(
          {},
          {
            [endpoint]: {
              data: response.data,
              total: response.hasOwnProperty("total") ? response.total : -1
            }
          }
        );
      }
    }
    return response;
  });
}

export const CALL_API = Symbol("Call API");
export const CACHE_API = Symbol("Cache API");

// middleware method
export default store => next => action => {
  const callAPI = action[CALL_API];
  const cache = action[CACHE_API];
  if (typeof callAPI === "undefined" && typeof cache === "undefined") {
    return next(action);
  }

  const actionWith = data => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    delete finalAction[CACHE_API];
    return finalAction;
  };

  if (cache && cache === API_CACHE_FLUSH_ALL) {
    next(actionWith({ type: API_CACHE_FLUSH_ALL }));
    next(actionWith({ type: API_DATA_FLUSH_ALL }));
    return next(actionWith(action));
  }
  console.debug("[DEBUG] - API Middleware", action);

  let { endpoint, method = "get", expecting_data = null } = callAPI;
  let options = { ...callAPI };
  delete options.endpoint;

  if (typeof endpoint === "function") {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }

  if (["get", "post", "put", "delete"].indexOf(method) < 0) {
    throw new Error(
      "Invalid API method! Specify one of [get, post, put, delete]."
    );
  }

  // endpoint must have GET parameters resolved
  endpoint = buildEndpoint(endpoint, options.query_params);
  const controller = extractController(endpoint);
  next(actionWith({ type: API_DATA_REQUEST, method, endpoint, controller }));

  // add cache request when is enabled
  var isCacheEnabled = process.env.REACT_APP_API_ENABLE_CACHE === "true";
  if (cache && cache.hasOwnProperty("isEnabled")) {
    isCacheEnabled = cache.isEnabled;
  }

  // only check cache if getting data form the API and cache is enabled
  if (method === "get" && isCacheEnabled) {
    // first check cache info
    next(actionWith({ type: API_CACHE_GET, endpoint, controller }));
    const state = store.getState();
    if (
      state.cache &&
      state.cache[controller] &&
      state.cache[controller][endpoint]
    ) {
      // cache still valid, check data exists in cache (redux)
      if (
        state.data &&
        state.data[controller] &&
        state.data[controller][endpoint]
      ) {
        console.debug(
          "[DEBUG] - API Middleware - Get from cache",
          state.data[controller][endpoint]
        );
        // return cached data
        return Promise.resolve({
          status: "OK",
          data: state.data[controller][endpoint].data,
          total: state.data[controller][endpoint].total
        });
      }
    } else {
      // cached expired, delete data if it exists
      if (
        state.data &&
        state.data[controller] &&
        state.data[controller][endpoint]
      ) {
        next(actionWith({ type: API_DATA_DELETE, endpoint, controller }));
      }
    }
  }

  // get data from API
  return callApi(endpoint, options || {}).then(
    response => {
      console.debug("[DEBUG] - callApi success", endpoint);
      if (method === "get") {
        // getting data from the API
        next(
          actionWith({ type: API_DATA_SUCCESS, endpoint, controller, response })
        );
        if (response[endpoint]) {
          // save data to cache, before returning
          if (isCacheEnabled) {
            next(
              actionWith({ type: API_CACHE_SET, endpoint, controller, cache })
            );
          }
          // return data
          return {
            status: "OK",
            data: response[endpoint].data,
            total: response[endpoint].total
          };
        }
      } else {
        next(actionWith({ type: API_DATA_SUCCESS, endpoint, controller }));
        // sending data to API, remove all local data and cache
        next(actionWith({ type: API_DATA_DELETE, endpoint, controller }));
        if (isCacheEnabled) {
          next(
            actionWith({ type: API_CACHE_DELETE, endpoint, controller, cache })
          );
        }
      }
      // just return ok
      let result = { status: "OK" };
      if (expecting_data) {
        // respond with data when at request was specified that is expected
        result.data = response[endpoint].hasOwnProperty("data")
          ? response[endpoint].data
          : undefined;
      }
      return result;
    },
    error => {
      let msg = error.msg ? String.toString(error.msg) : "";
      // check error type
      if (error.code === 401) {
        // unauthorized, remove api token
        console.debug("[DEBUG] - api_middleware - Removing X-Api-Token to API");
        delete api.defaults.headers["X-Api-Token"];
        // and log user out
        next(actionWith({ type: "LOGOUT" }));
        // set error msg
        next(
          actionWith({
            type: "SET_TOP_MSG",
            payload: {
              type: "error",
              autoClose: false,
              unique: true,
              msg: ["Your session is invalid, plase login again", error.msg]
            }
          })
        );
        return false;
      }
      next(
        actionWith({
          type: API_DATA_FAILURE,
          error: msg || "Something bad happened",
          controller
        })
      );
      // remove all local data and cache
      next(actionWith({ type: API_DATA_DELETE, endpoint, controller }));
      if (isCacheEnabled) {
        next(
          actionWith({ type: API_CACHE_DELETE, endpoint, controller, cache })
        );
      }
      console.debug("[DEBUG] - callApi error", error);
      return error;
    }
  );
};

// remove a given entry from state, either a whole controller segment,
// or an entry from within a controller
export function deleteFromState(state, controller, endpoint = "") {
  let _state = { ...state };
  if (endpoint !== "") {
    delete _state[controller][endpoint];
  } else {
    delete _state[controller];
  }
  return _state;
}
